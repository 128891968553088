import React from "react"

import Layout from "components/layout"

const Sandbox = () => {
  return (
    <Layout>
      <section className="py-main"></section>
    </Layout>
  )
}

export default Sandbox
